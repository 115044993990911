<template>
    <v-container fluid>
        <v-card elevation="0" outlined>
            <v-card-title class="headline">
                {{$t('erp.lang_AddAllergen')}}
            </v-card-title>
            <v-divider class="pa-0 ma-0"/>
            <v-card-text>
                <v-row align="center" align-content="center" justify="center" no-gutters >
                    <v-col cols="12" md="6" sm="10">
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="allergenAdd"
                                      :label="$t('erp.lang_Allergene')"
                                      autocomplete="off"
                                      required
                        ></v-text-field>
                        <v-btn color="primary"
                               class="ma-0"
                               :disabled="allergenAdd.length < 1"
                               :loading="loading"
                               @click="addData"
                               block large>
                            {{$t('generic.lang_add') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
                                :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                                :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                                :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
        </div>
    </v-container>
</template>

<script>
    import {ENDPOINTS} from '../../../../config'
    import {Events} from "../../../../plugins/events";
    import mixin from '../../../../mixins/KeyboardMixIns';

    export default {
        name: "AllergensCreateComponent",
        mixins:[mixin],
        data(){
            return{
                ENDPOINTS,
                loading: false,
                allergenAdd: "",
            }
        },
        methods:{
            addData: function () {
                let self = this;
                self.loading=true;
                this.axios.post(ENDPOINTS.ERP.ALLERGENE.CREATE, {
                    addAllergeneName: this.allergenAdd
                }).then((res) => {
                    if (res.data.status === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            message: this.$t('erp.lang_allergen_added'),
                            color: "success"
                        });
                        self.loading=false;
                        self.allergenAdd = "";

                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                        self.loading=false;
                    }
                }).catch(() => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                    self.loading=false;
                });
            },
        },
    }
</script>

<style scoped>

</style>